<template>
  <div class="personal-data">
    <!-- 图片  -->
    <div class="avatar">
      <img src="../../assets/images/avatar.png">
      <p>前往用户中心修改</p>
    </div>
    <div>
      <!--要修改的内容 -->
      <ul class="form">
        <!--名称-->
        <li>
          <label>昵称</label>
          <span>{{form.nickname}}</span>
          <!-- <div class="form-nickname">
            <span v-if="!isEditName">{{form.nickname}}</span>
            <el-input
              v-else
              v-model="form.nickname"
              placeholder="请输入名称"
              maxlength="20"
              ref="nameInput"
              show-word-limit
            />
            <i class="el-icon-edit" @click="handleEdit"></i>
          </div> -->
          <router-link to="#" target="_blank" class="outer-link">前往用户中心修改</router-link>
        </li>
        <!--选择性别 -->
        <li>
          <label>性别</label>
          <GenderRadio v-model="form.sex"/>
        </li>
        <!-- 输入公司名称-->
        <li>
          <label>公司</label>
          <el-input
            v-model="form.company"
            type="text"
            placeholder="请输入公司名称"
            maxlength="50"
            show-word-limit
          />
        </li>
        <li>
          <!-- 输入岗位  -->
          <label>岗位</label>
          <el-input
            v-model="form.position"
            type="text"
            placeholder="请输入岗位"
            maxlength="50"
            show-word-limit
          >
          </el-input>
        </li>
        <!-- 主页  -->
        <li>
          <label>个人主页</label>
          <el-input
            v-model="form.homepage"
            type="text"
            placeholder="请输入个人主页地址"
            maxlength="200"
            show-word-limit
          >
          </el-input>
        </li>
        <!--  签名 -->
        <li>
          <label>个性签名</label>
          <el-input
            v-model="form.personalSignature"
            type="textarea"
            placeholder="请输入内容"
            maxlength="200"
            show-word-limit
          >
          </el-input>
        </li>
      </ul>
      <!--  保存 -->
      <div class="opera">
        <p class="tip">站长提醒：资料修改后实时生效，但请谨慎填写，如被他人举报或站点主动审核发现不合法内容，站点将对账号严肃处理！</p>
        <Button type="solid" theme="function" size="big" @click="save" :disabled="isWorking">保存修改</Button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { updateUserData } from '@/api/user'
import GenderRadio from '@/components/common/GenderRadio'
export default {
  components: { GenderRadio },
  data () {
    return {
      isWorking: false,
      isEditName: false,
      form: {
        nickname: '',
        avatar: '',
        sex: 'FEMALE',
        company: '',
        position: '',
        homepage: '',
        personalSignature: ''
      }
    }
  },
  computed: {
    ...mapState(['userInfo'])
  },
  methods: {
    handleEdit () {
      this.isEditName = true
      this.$refs.nameInput.focus()
    },
    save () {
      updateUserData(this.form)
        .then(() => {
          this.$tip.success('修改成功')
        })
        .catch(e => {
          this.$tip.apiFailed(e)
        })
    },
    init () {
      for (const key in this.form) {
        this.form[key] = this.userInfo[key] == null ? this.form[key] : this.userInfo[key]
      }
    }
  },
  created () {
    this.init()
  }
}
</script>

<style scoped lang="scss">
@import "../../assets/style/variables";
.personal-data{
  display: flex;
  padding: 20px 80px 50px 80px;
  // 头像
  .avatar{
    width: 120px;
    flex-shrink: 0;
    text-align: center;
    img{
      width: 78px;
      height: 78px;
      border-radius: 50%;
      margin-top: 20px;
      margin-bottom: 5px;
    }
    p{
      margin: 0;
      color: $primary-color;
    }
  }
  // 表单
  .form{
    flex-grow: 1;
    box-sizing: border-box;
    margin: 20px 0 0  0;
    padding: 0;
    li{
      display: flex;
      margin-bottom: 25px;
      line-height: 40px;
      // 属性名称
      label{
        text-align: right;
        width: 60px;
        flex-shrink: 0;
        color: $font-color-third;
        margin-right: 30px;
      }
      .form-nickname {
        display: flex;
        align-items: center;
        i {
          margin-left: 10px;
        }
      }
      // 名称
      span{
        flex-grow: 1;
      }
      // 外部链接
      a.outer-link {
        flex-shrink: 0;
        text-align: right;
        font-size: 14px;
        line-height: 40px;
        color: $primary-color;
      }
      // 输入框
      /deep/ .el-input{
        font-size: 15px;
        .el-input__inner{
          height: 40px;
          border-radius: 30px;
          background-color: $background-color-second;
          border: 0;
        }
        .el-input__count-inner{
          background-color: $background-color-second;
        }
      }
      // 文本域
      /deep/ .el-textarea {
        font-size: 15px;
        .el-textarea__inner{
          resize: none;
          height: 100px;
          background-color: $background-color-second;
          border: 0;
          border-radius: 10px;
          padding: 10px 16px;
        }
        .el-input__count {
          line-height: initial;
          background-color: transparent;
        }
      }
    }
  }
  // 操作
  .opera {
    margin-left: 90px;
    .tip {
      color: $font-color-third;
    }
  }
}
</style>
