<template>
  <div class="gender-radio">
    <button :class="{ selected: value === 'FEMALE' }" @click="$emit('input', 'FEMALE')"><i class="el-icon-female"></i>女</button>
    <button :class="{ selected: value === 'MALE' }" @click="$emit('input', 'MALE')"><i class="el-icon-male"></i>男</button>
    <button :class="{ selected: value === 'LOCK' }" @click="$emit('input', 'LOCK')"><i class="el-icon-lock"></i>保密</button>
  </div>
</template>

<script>
export default {
  name: 'GenderRadio',
  props: {
    value: {
      default: 'FEMALE'
    }
  }
}
</script>

<style scoped lang="scss">
@import "../../assets/style/variables";
$male-color: #80beff;
$female-color: #ffb7e0;
$lock-color: #999;
.gender-radio {
  button{
    width: 80px;
    height: 40px;
    border-radius: 20px;
    border: 0;
    background-color: $background-color-second;
    margin-right: 15px;
    font-size: 15px;
    color: $font-color-second;
    cursor: pointer;
    transition: all ease .15s;
    &:hover, &.selected {
      color: #fff;
      i{
        color: #fff;
      }
    }
    // 女
    &:nth-of-type(1){
      &:hover, &.selected {
        background-color: $female-color;
      }
    }
    // 男
    &:nth-of-type(2){
      &:hover, &.selected {
        background-color: $male-color;
      }
    }
    //保密
    &:nth-of-type(3){
      &:hover, &.selected {
        background-color: $lock-color;
      }
    }
    i{
      font-size: 18px;
      margin-right: 5px;
      position: relative;
      top: 1px;
    }
  }
}
</style>
